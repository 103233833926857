import React from 'react';
import parse from 'html-react-parser'
import {Box,Grid,Item} from "@mui/material";
import {Accordion,AccordionSummary,AccordionDetails} from "./Accordion"; 
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';



 



function PortfolioBlock(props) {
   const {image, image1, image2, imageText1, imageText2, source, title, content, index} = props;
   const [expanded, setExpanded] = React.useState(index);
   
   const handleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

   return (
      <Accordion expanded={expanded === index} onChange={handleChange(index)} >
         <AccordionSummary aria-controls="panel{index}d-content" id="panel{index}d-header">
            <Typography><h2 style={{fontSize: '1rem'}}> {title}</h2></Typography>
         </AccordionSummary>
         <AccordionDetails>
            <Typography>
               <Box style={{margin: '1em 3em 1em 3em'}}>

                  {parse(content)}
                  
                  <h3 style={{fontSize: '1rem', margin: '2em 0 0.5em 0'}}>Awards </h3>
                  <p style={{fontSize: '1rem'}}></p>
                  <Grid container spacing={3} style={{fontSize: '1rem', margin: '0.5em '}} >
                     <p style={{fontSize: '1rem'}}></p>
                     <Grid item xs={4}>
                        <Box component={'img'} src={image1} alt={imageText1}/>
                     </Grid>
                     <Grid item xs={8}>
                        <p>{imageText1}</p>
                     </Grid>
                     <Grid item xs={4}>
                        <Box component={'img'} src={image2} alt={imageText2}/>
                     </Grid>
                     <Grid item xs={8}>
                        <p>{imageText2}</p>
                     </Grid>
                  </Grid>
               </Box>
            </Typography>
            </AccordionDetails>
            
            
      </Accordion>
   );
}

export default PortfolioBlock;