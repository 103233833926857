import React from 'react';
import PortfolioBlock from "./PortfolioBlock";
import {Box, Grid} from "@mui/material";
import {info} from "../../info/Info";

export default function Portfolio() {
    return (
        <Box style={{margin: '1em 0 1em 0'}}>
            <Grid container justifyContent={'center'}>
                {info.portfolio.map((project, index) => (
                   <Grid item xs={12} md={6} key={index}>
                       <PortfolioBlock image={project.image} image1={project.image1} image2={project.image2} imageText1={project.imageText1} imageText2={project.imageText2} title={project.title} content={project.content} index={index}/>
                   </Grid>
                ))}
            </Grid>
        </Box>
    );
};