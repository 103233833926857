

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Danny",
    lastName: "Guan",
    initials: "DG", // the example uses first and last, but feel free to use three or more if you like.
    position: "Technology Executive",
    position1: "Engineer",
    position2: "Architect",
    selfPortrait: "./img/self.png", // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '🌎',
            text: 'based in Sydney AU'
        },
        {
            emoji: "💼",
            text: "https://www.linkedin.com/in/dannyguan/"
        },
        {
            emoji: "📧",
            text: "danny@guan.au"
        }
    ],
    socials: [
        {
            link: "https://www.linkedin.com/in/dannyguan/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        }
    ],
    bio: "I'm Danny, a Technology Executive, an engineer at heart. ",
    biocont: "Technology is part of my everyday life. By day, I lead and inspire teams to deliver and perform their best, and by night implement technology to improve my day-to-day life while entertaining my three kids.",
    biocont1: "Every Technology advancement is exciting and has its place when used in the right way, and I believe artificial intelligence will inevitably rule us all one day.",
    skills:
        {
            proficientWith: ['javascript', 'react', 'git', 'github', 'bootstrap', 'html5', 'css3', 'figma'],
            exposedTo: ['nodejs', 'python', 'adobe illustrator']
        }
    ,
    hobbies: [
        {
            label: 'reading',
            emoji: '📖'
        },
        {
            label: 'theater',
            emoji: '🎭'
        },
        {
            label: 'movies',
            emoji: '🎥'
        },
        {
            label: 'cooking',
            emoji: '🌶'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
    {
        title: "General Manager of Technology @ WooliesX",
        source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
        content: "<p style={{fontSize: '1.2rem', margin: '1em 0 1em 0'}}>Leading a large team with a broad remit looking after the end-to-end Digital Capabilities for the Woolworths Group with over 250 Engineers, Product Managers, Delivery Leads, and Quality Assurance.</p><p style={{fontSize: '1.2rem', margin: '0.5em 0 0.5em 0'}}>Capabilities include Data Platforms, CRM, Contact Centre Solutions, Conversational AI (Chat and Voice), Promotion Services, E-commerce platforms, Product Information Management, Mobile Apps, and Cloud Platforms.</p><p style={{fontSize: '1.2rem', margin: '0.5em 0 0.5em 0'}}>Managing and delivering Core Technology Initiatives, including Contact Centre Cloud Migration, Cloud Platform Consolidation, Data Platform Consolidation, Cloud Utilization optimisations, Platform Reliability Uplift, and domain Services to break down Monolith systems. </p>",
        image: "./img/20220915-Tech Foundations Town Hall-93.jpg",
        image1: "./img/IMG_20220616_084351.jpg",
        imageText1: "Winner of ITNews Consumer Awards 2022 - E-Receipts",
        image2: "./img/20230330_205735.jpg",
        imageText2: "Winner of ITNews Consumer Awards 2023 - Contact Centre Cloud Migration",
    },/**{
            title: "General Manager of Technology @ WooliesX",
            source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
            content: "<p style={{fontSize: '1.2rem', margin: '1em 0 1em 0'}}>Leading a large team with a broad remit looking after the end-to-end Digital Capabilities for the Woolworths Group with over 250 Engineers, Product Managers, Delivery Leads, and Quality Assurance.</p><p style={{fontSize: '1.2rem', margin: '0.5em 0 0.5em 0'}}>Capabilities include Data Platforms, CRM, Contact Centre Solutions, Conversational AI (Chat and Voice), Promotion Services, E-commerce platforms, Product Information Management, Mobile Apps, and Cloud Platforms.</p><p style={{fontSize: '1.2rem', margin: '0.5em 0 0.5em 0'}}>Managing and delivering Core Technology Initiatives, including Contact Centre Cloud Migration, Cloud Platform Consolidation, Data Platform Consolidation, Cloud Utilization optimisations, Platform Reliability Uplift, and domain Services to break down Monolith systems. </p>",
            image: "./img/20220915-Tech Foundations Town Hall-93.jpg",
            image1: "./img/IMG_20220616_084351.jpg",
            imageText1: "Winner of ITNews Consumer Awards 2022 - E-Receipts",
            image2: "./img/20230330_205735.jpg",
            imageText2: "Winner of ITNews Consumer Awards 2023 - Contact Centre Cloud Migration",
        }, ,
        {
            title: "General Manager of Technology @ WooliesX",
            source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
            content: "<p ></p>",
            image: "https://lh3.googleusercontent.com/u/0/drive-viewer/AITFw-xB25Jqzc5juw3NAiM-QPKPXqbIWhnbC7FieHM0jIACTK_jK9KJtGi221aUcAzQSOrZBE9du6qdgXO_GqdIGnaUu8Lr=w900-h1283",
            image1: "https://lh3.googleusercontent.com/u/0/drive-viewer/AITFw-w8Jsd4F_Fa1QMXdOROVEd-r7stKApZfQSqIoHmZHvxv34sMJnjOyvZRUqL6tfXAjtUFh1hqYDOnblQISjynuV_xNoH4g=w600-h1528",
            imageText1: "Winner of ITNews Consumer Awards 2022 - E-Receipts",
            image2: "https://lh3.googleusercontent.com/u/0/drive-viewer/AITFw-wwhkCZs6PbyHGkdnVN2LSdXPY4H0oPDagGbRQpb3Zob25GQyKcA2QcOrjHhj8SObhlz7H51sjzla6U8kzHRJtoFhFy=w800-h1528",
            imageText2: "Winner of ITNews Consumer Awards 2023 - Contact Centre Cloud Migration",
        },
        {
            title: "General Manager of Technology @ WooliesX",
            source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
            content: "<p ></p>",
            image: "https://lh3.googleusercontent.com/u/0/drive-viewer/AITFw-xB25Jqzc5juw3NAiM-QPKPXqbIWhnbC7FieHM0jIACTK_jK9KJtGi221aUcAzQSOrZBE9du6qdgXO_GqdIGnaUu8Lr=w900-h1283",
            image1: "https://lh3.googleusercontent.com/u/0/drive-viewer/AITFw-w8Jsd4F_Fa1QMXdOROVEd-r7stKApZfQSqIoHmZHvxv34sMJnjOyvZRUqL6tfXAjtUFh1hqYDOnblQISjynuV_xNoH4g=w600-h1528",
            imageText1: "Winner of ITNews Consumer Awards 2022 - E-Receipts",
            image2: "https://lh3.googleusercontent.com/u/0/drive-viewer/AITFw-wwhkCZs6PbyHGkdnVN2LSdXPY4H0oPDagGbRQpb3Zob25GQyKcA2QcOrjHhj8SObhlz7H51sjzla6U8kzHRJtoFhFy=w800-h1528",
            imageText2: "Winner of ITNews Consumer Awards 2023 - Contact Centre Cloud Migration",
        }*/
    ]
}